import React from "react";
import { Link } from "react-router-dom";
import { BdFlagIcon, UsaFlagIcon } from "../svgs";
// import basisLogo from "../../../public/basislogo.png";
function Footer(props) {
	const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
	return (
		<>
			<footer className={props.footerAddclass}>
				<div className="container">
					<div className="footer-top">
						<div className="row">
							<div className="col-md-3 col-lg-3 col-xl-3">
								<div className="footer-widget">
									<div className="footer-logo">
										<Link scrollTo={scrollTop} to={"/"}>
											<img
												src={process.env.PUBLIC_URL + "/img/white-logo.svg"}
												alt="FooterImg"
												height={100}
											/>
										</Link>
									</div>
									<p>
										UNLOCK THE POWER OF INNOVATION WITH OUR SOFTWARE SOLUTIONS
									</p>
									<ul className="social-media-icons">
										{/* <li>
                      <a href="https://www.facebook.com/">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li> */}
										<li>
											<a href="https://www.linkedin.com/company/vector360bd">
												{/* <i className="fab fa-twitter" /> */}
												<i className="bi bi-linkedin"></i>
											</a>
										</li>
										<li>
											<a href="https://www.instagram.com/vector360.bd">
												<i className="bi bi-instagram"></i>
											</a>
										</li>
										<li>
											<a href="https://www.facebook.com/people/Vector360/61562323470367">
												<i className="bi bi-facebook"></i>
											</a>
										</li>

										{/* <li>
                      <a href="https://www.instagram.com/">
                        <i className="fab fa-instagram" />
                      </a>
                    </li> */}
									</ul>

									<div
										style={{
											display: "flex",
											gap: "5px",
											fontSize: "14px",
										}}
									>
										<Link
											to={`${process.env.PUBLIC_URL}/privacy-policy`}
											onClick={scrollTop}
											style={{
												color: "white",
											}}
											className="new-terms-item"
										>
											Privacy Policy
										</Link>
										{/* <a
                          href="#privacy-policy"
                          className="text-decoration-none"
                        >
                          Privacy Policy
                        </a> */}

										{/* Separator (|) */}
										<span
											style={{
												color: "white",
											}}
										>
											|
										</span>

										{/* Terms of Use link */}
										<Link
											to={`${process.env.PUBLIC_URL}/terms-of-use`}
											onClick={scrollTop}
											style={{
												color: "white",
											}}
											className="new-terms-item"
										>
											Terms of Use
										</Link>

										<span
											style={{
												color: "white",
											}}
										>
											|
										</span>
										<Link
											to={`${process.env.PUBLIC_URL}/refund`}
											onClick={scrollTop}
											style={{
												color: "white",
											}}
											className="new-terms-item"
										>
											Refund
										</Link>
									</div>
								</div>
							</div>
							<div className="col-md-3 col-lg-3 col-xl-3">
								<div className="footer-widget">
									<h4>Our Services</h4>
									<ul className="footer-menu">
										<li>
											<Link
												to={`${process.env.PUBLIC_URL}/service-details`}
												onClick={scrollTop}
											>
												Vector Strategy &amp; Research
											</Link>
										</li>
										<li>
											<Link
												to={`${process.env.PUBLIC_URL}/service-details`}
												onClick={scrollTop}
											>
												Vector Web Development
											</Link>
										</li>
										<li>
											<Link
												to={`${process.env.PUBLIC_URL}/service-details`}
												onClick={scrollTop}
											>
												Vector Web Solution
											</Link>
										</li>
										<li>
											<Link
												to={`${process.env.PUBLIC_URL}/service-details`}
												onClick={scrollTop}
											>
												Vector Digital Marketing
											</Link>
										</li>
										<li>
											<Link
												to={`${process.env.PUBLIC_URL}/service-details`}
												onClick={scrollTop}
											>
												Vector App Design
											</Link>
										</li>
										<li>
											<Link
												to={`${process.env.PUBLIC_URL}/service-details`}
												onClick={scrollTop}
											>
												Vector App Development
											</Link>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-md-3 col-lg-3 col-xl-3">
								<div className="footer-widget">
									<h4>Quick Links</h4>
									<ul className="footer-menu">
										<li>
											<Link
												to={`${process.env.PUBLIC_URL}/about`}
												onClick={scrollTop}
											>
												About Vector
											</Link>
										</li>
										<li>
											<Link
												to={`${process.env.PUBLIC_URL}/service`}
												onClick={scrollTop}
											>
												Vector Services
											</Link>
										</li>
										<li>
											<Link
												to={`${process.env.PUBLIC_URL}/project`}
												onClick={scrollTop}
											>
												Vector Project
											</Link>
										</li>
										{/* <li>
                      <Link to={`${process.env.PUBLIC_URL}/blog`} onClick={scrollTop}>Blog</Link>
                    </li> */}
										<li>
											<Link
												to={`${process.env.PUBLIC_URL}/career`}
												onClick={scrollTop}
											>
												Vector Career
											</Link>
										</li>

										<li>
											<Link
												className="text-decoration-none"
												to={`${process.env.PUBLIC_URL}/privacy-policy`}
												onClick={scrollTop}
											>
												Privacy Policy
											</Link>
											{/* Separator (|) */}
										</li>

										<li>
											<Link
												className="text-decoration-none"
												to={`${process.env.PUBLIC_URL}/terms-of-use`}
												onClick={scrollTop}
											>
												Terms of Use
											</Link>
										</li>

										<li>
											<Link
												className="text-decoration-none"
												to={`${process.env.PUBLIC_URL}/refund`}
												onClick={scrollTop}
											>
												Refund
											</Link>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-md-3 col-lg-3 col-xl-3">
								<div className="footer-widget">
									<h4>Contacts</h4>
									<div className="number">
										<div className="num-icon">
											<i className="fas fa-phone-alt" />
										</div>
										<div className="phone">
											<a
												href="tel:8801790092209"
												style={{
													display: "flex",
													gap: "5px",
													alignItems: "center",
												}}
											>
												<span>
													<BdFlagIcon width={25} />
												</span>
												<span>+880 1790092209</span>
											</a>
											<a
												href="tel:16143000676"
												style={{
													display: "flex",
													gap: "5px",
													alignItems: "center",
												}}
											>
												{" "}
												<span>
													<UsaFlagIcon width={25} />
												</span>
												<span> +1 614-300-0676</span>
											</a>
										</div>
									</div>
									<div className="office-mail">
										<div className="mail-icon">
											<i className="far fa-envelope" />
										</div>
										<div className="email">
											<a href="mailto:support@vector360bd.com">
												support@vector360bd.com
											</a>
										</div>
									</div>
									{/* <div className="number">
										<div className="num-icon">
											<i className="bi bi-whatsapp" />
										</div>
										<div className="phone">
											<a
												href="https://wa.me/+8801790092209 "
												target="_blank"
												rel="noopener noreferrer"
											>
												+880 1790092209
											</a>
											<a
												href="https://wa.me/+16143000676"
												target="_blank"
												rel="noopener noreferrer"
											>
												+1614-300-0676
											</a>
										</div>
									</div> */}
									{/* https://wa.me/1XXXXXXXXXX */}
									<a href="https://maps.app.goo.gl/GGAXJqJYBpipJxP2A">
										<div className="address">
											<div className="address-icon">
												<i className="fas fa-map-marker-alt" />
											</div>
											<p>
												Macro Lily, Plot 8/2, Block D, Lalmatia, Dhaka - 1207,
												Bangladesh
											</p>
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="footer-bottom">
						<div className="row align-items-center  justify-content-center">
							<div className=" footer-icon-logo ">
								<img
									src={process.env.PUBLIC_URL + "/basislogo.webp"}
									alt="basis-logo"
								/>
								<img
									src={process.env.PUBLIC_URL + "/bacco.png"}
									alt="basis-logo"
								/>
								<img
									src={process.env.PUBLIC_URL + "/ecab.png"}
									alt="basis-logo"
								/>
								<img
									src={process.env.PUBLIC_URL + "/ISO.webp"}
									alt="basis-logo"
								/>
								<img
									src={process.env.PUBLIC_URL + "/EUSA.png"}
									alt="basis-logo"
								/>
								<img
									src={process.env.PUBLIC_URL + "/TSN.png"}
									alt="basis-logo"
								/>
							</div>

							<div
								className="col-12 col-md-4 col-lg-4 col-xl-5 "
								style={{
									paddingTop: "10px",
								}}
							>
								<div className="copy-txt d-flex flex-column flex-md-row justify-content-center justify-content-md-center align-items-center align-items-md-center text-center text-md-left">
									{/* Privacy Policy link */}
									<span className="mb-2 mb-md-0">
										<Link
											className="text-decoration-none"
											to={`${process.env.PUBLIC_URL}/privacy-policy`}
											onClick={scrollTop}
										>
											Privacy Policy
										</Link>
									</span>

									{/* Separator (|) */}
									<span className="mx-2 mb-2 mb-md-0">|</span>

									{/* Terms of Use link */}
									<span className="mb-2 mb-md-0">
										<Link
											className="text-decoration-none"
											to={`${process.env.PUBLIC_URL}/terms-of-use`}
											onClick={scrollTop}
										>
											Terms of Use
										</Link>
									</span>
									<span className="mx-2 mb-2 mb-md-0">|</span>
									<span className="mb-2 mb-md-0">
										<Link
											className="text-decoration-none"
											to={`${process.env.PUBLIC_URL}/refund`}
											onClick={scrollTop}
										>
											Refund
										</Link>
									</span>

									{/* Copyright text */}
									<span className="mb-0 mt-2 mt-md-0 ml-md-3">
										&nbsp;&nbsp;&nbsp;© Copyright {new Date().getFullYear()}{" "}
										<b>Vector360</b>
									</span>
								</div>
							</div>

							{/* <div className="col-12 col-md-8 col-lg-8 col-xl-7">
                <ul className="footer-bottom-menu">
                  <li>
                    <Link to={"#"}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to={"#"}>Terms of Use</Link>
                  </li>
                </ul>
              </div> */}
						</div>
					</div>
				</div>
				<a
					href="https://wa.me/+8801790092209 "
					// target="_blank"
					// rel="noopener noreferrer"
				>
					<div
						// style={{
						// 	position: "fixed",
						// 	width: "100px",
						// 	height: "100px",
						// 	// backgroundColor: "red",
						// 	borderRadius: "100%",
						// 	bottom: "50px",
						// 	zIndex: "10000",
						// 	right: "40px",
						// }}
						className="float-wp-button"
					>
						<div
							style={{
								color: "white",
								minWidth: "200px",
								padding: "7px 0px 7px 10px",
								whiteSpace: "nowrap",
								borderRadius: "10px 0 0 10px",
								background: "green",
								display: "flex",
								gap: "5px",
								placeItems: "center",
							}}
						>
							<span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="35"
									height="35"
									fill="#ffcb05"
									class="bi bi-whatsapp"
									viewBox="0 0 16 16"
								>
									<path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
								</svg>
							</span>
							{/* <i
							className="bi bi-whatsapp"
							style={{
								width: "100px",
							}}
						/> */}
							<span className="float-wp-text">What&apos;s App</span>
						</div>
					</div>
				</a>
			</footer>
		</>
	);
}

export default Footer;
