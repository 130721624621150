import React from "react";
import { Link } from "react-router-dom";
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import {
  BrandIcon,
  MobileAppDevelopmentIcon,
  SoftwareDevelopmentIcon,
  SoftwareTestingIcon,
  TechRecrutingAgencyIcon,
  UiuxIcon,
  WebsiteDevelopmentIcon,
  CustomerManagementIcon,
  EnvironmentIcon,
  EnvironmentSocialIcon
} from "../../svgs";
SwiperCore.use([Navigation, Autoplay]);

function ServiceArea(props) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const serviceSlider = {
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    slidesPerView: 3,
    spaceBetween: 30,

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  };
  return (
    <>
      <section className={`${"services-area sec-mar"} ${props.layoutClass}`}>
        <div className="container">
          <div
            className="title-wrap wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className={`${"sec-title"} ${props.white}`}>
              <span>Our Solutions</span>
              <h2>Services</h2>
              <p>Transforming Ideas into Digital Realities</p>
            </div>
          </div>
          <Swiper {...serviceSlider} className="swiper services-slider">
            <div className="swiper-wrapper">
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>01</span>
                  <div className="icon">
                    {<SoftwareDevelopmentIcon width={80} height={80} />}
                  </div>
                  <h4>Software Development</h4>
                  <p className="text-justify">
                    Our cutting-edge software development services will help you
                    take your company to the next level.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>02</span>
                  <div className="icon">
                    <WebsiteDevelopmentIcon width={80} height={80} />
                  </div>
                  <h4>Website Development</h4>
                  <p className="text-justify">
                    Achieve online success with our top-notch website
                    development services.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>03</span>
                  <div className="icon">
                    <MobileAppDevelopmentIcon width={80} height={80} />
                  </div>
                  <h4>Mobile App Development</h4>
                  <p className="text-justify">
                    Unlock the full potential of your business with our
                    professional mobile app development services.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>04</span>
                  <div className="icon">
                    <TechRecrutingAgencyIcon width={80} height={80} />
                  </div>
                  <h4>Tech Recruiting Agency</h4>
                  <p className="text-justify">
                    Empowering tech careers through tailored connections,
                    discover top-tier opportunities with our specialised
                    recruiting agency service.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>05</span>
                  <div className="icon">
                    <SoftwareTestingIcon width={80} height={80} />
                  </div>
                  <h4>Software Testing</h4>
                  <p className="text-justify">
                    Ensure the quality and reliability of your software with our
                    professional testing services.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>06</span>
                  <div className="icon">
                    <UiuxIcon width={80} height={80} />
                  </div>
                  <h4>UI/UX Design</h4>
                  <p className="text-justify">
                    Improve user engagement and boost conversions with our
                    professional UI/UX design services.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>07</span>
                  <div className="icon">
                    <BrandIcon width={80} height={80} />
                  </div>
                  <h4>Branding</h4>
                  <p className="text-justify">
                    Establish a strong brand identity with our professional
                    branding services.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>08</span>
                  <div className="icon">
                    <CustomerManagementIcon width={80} height={80} />
                  </div>
                  <h4>Customer care management</h4>
                  <p className="text-justify">
                  Enhance your customer experience and streamline operations with Vector360 expert Business Process Outsourcing through our dedicated call center services
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>09</span>
                  <div className="icon">
                    <EnvironmentSocialIcon width={80} height={80} />
                  </div>
                  <h4>Environmental and Social Impact Assessment</h4>
                  <p className="text-justify">
                  Explore our tailored Environmental and Social Impact Assessment solutions, designed to address both environmental conservation and community welfare for a holistic approach to project development.

                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>10</span>
                  <div className="icon">
                    <EnvironmentIcon width={80} height={80} />
                  </div>
                  <h4>Environmental impact assessment</h4>
                  <p className="text-justify">
                  Vector360BD offers comprehensive Environmental Impact Assessment services, ensuring sustainable development and environmental compliance for your projects.


                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
          <div className="swiper-button-next" />
          <div className="swiper-button-prev" />
        </div>
      </section>
    </>
  );
}

export default ServiceArea;
