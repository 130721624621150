import AnimateLine from "../../common/AnimateLine";
import SubscribArea from "../../common/SubscribArea";
import ProjectWrap from "../project/ProjectWrap";
// import PriceCardWrap from "../service/PriceCardWrap";
// import TeamWrap from "../team/TeamWrap";
import AboutArea from "./AboutArea";
import FeaturesArea from "./FeaturesArea";
import HeroArea from "./HeroArea";
// import LatesPost from "./LatesPost";
import PartnarArea from "./PartnarArea";
import ServiceArea from "./ServiceArea";
// import { Helmet } from "react-helmet";
import SEO from "../../../config/seo.config";
// import Testimonial from "./Testimonial";

function HomePageOne() {
	return (
		<>
			<SEO
				canonicalUrl={"https://vector360bd.com"}
				title="Leading Software Development Company in Bangladesh"
				description="Vector360 is the leading software development company in Bangladesh. We specialize in custom web development, mobile app development, and UX/UI design"
			/>
			<div className="creasoft-wrap">
				<AnimateLine />
				<HeroArea />
				<ServiceArea />
				<AboutArea image="/img/logo.svg" />
				<FeaturesArea />
				<ProjectWrap />
				<PartnarArea />
				{/* <PriceCardWrap /> */}
				{/* <Testimonial /> */}
				{/* <TeamWrap /> */}
				{/* <LatesPost /> */}
				<SubscribArea />
			</div>
		</>
	);
}

export default HomePageOne;
