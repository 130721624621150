import React from "react";
import { Link } from "react-router-dom";

function HeroArea() {
	const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
	return (
		<>
			<section className="hero-area">
				<div
					className="ken-burns-slideshow"
					// style={{ borderRadius: "500px 0 0 0" }}
				>
					<img
						src={process.env.PUBLIC_URL + "/img/banner-image.webp"}
						alt="HeroBanner"
						// height={700}
						// style={{ objectFit: "cover" }}
					/>
				</div>
				<div className="verticale-social">
					<ul className="vertical-media">
						{/* <li>
              <a href="https://www.facebook.com/">Facebook</a>
            </li> */}
						<li>
							<a href="https://www.instagram.com/vector360.bd">Instagram</a>
						</li>
						<li>
							<a href="https://www.linkedin.com/company/vector360bd">
								Linkedin
							</a>
						</li>
						<li>
							<a href="https://www.facebook.com/people/Vector360/61562323470367">
								Facebook
							</a>
						</li>
					</ul>
				</div>
				<div className="hero-wrapper">
					<div className="container">
						<div className="row">
							<div className="col-lg-6">
								<div className="hero-content">
									<h1 className="hero-text">
										Unlock the Power of Innovation
										<span>with Our Solutions.</span>
									</h1>
									<p>
										Vector360: Crafting agile, mobile app and websites with
										adaptability, transparency, and collaboration for superior
										quality and stakeholder satisfaction.
									</p>
									<div className="buttons">
										<div className="cmn-btn">
											<div className="line-1" />
											<div className="line-2" />
											<Link
												to={`${process.env.PUBLIC_URL}/about`}
												onClick={scrollTop}
											>
												About Us
											</Link>
										</div>
										<div className="cmn-btn layout-two">
											<div className="line-1" />
											<div className="line-2" />
											<Link
												to={`${process.env.PUBLIC_URL}/project`}
												onClick={scrollTop}
											>
												See Project
											</Link>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-6" />
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default HeroArea;
