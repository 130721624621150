import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";

function AboutArea(props) {
	const [isOpen, setOpen] = useState(false);

	return (
		<>
			<section className="about-area sec-mar-bottom">
				<div className="container">
					<div className="row">
						<div
							className="col-lg-6 or-2 wow animate fadeIn"
							data-wow-delay="200ms"
							data-wow-duration="1500ms"
						>
							<div className={`${"sec-title layout2"} ${props.white}`}>
								<span>Get To Know</span>
								<h2>About Us</h2>
							</div>
							<div className={`${"about-left"} ${props.layoutClass}`}>
								<h3>
									Transforming Ideas into Reality by Crafting Software,
									Websites, and Successful Launches.
								</h3>
								<p className="text-justify">
									Vector360 is a leading software development company in
									Bangladesh, specialising in Agile-based solutions for mobile
									and web applications. We prioritise adaptability,
									transparency, and collaboration to exceed client objectives.
									We aim to ensure client satisfaction and technological
									advancement.
								</p>
								<div className={`${"company-since"} ${props.layoutClass}`}>
									<div className="company-logo">
										<img
											src={`${process.env.PUBLIC_URL} ${props.image}`}
											alt="about-images"
											width={50}
										/>
									</div>
									<strong>#1</strong>
									<h4>
										Best Creative IT Agency And Solutions{" "}
										<span>Since 2022.</span>
									</h4>
								</div>
							</div>
						</div>
						<div
							className="col-lg-6 or-1 wow animate fadeIn"
							data-wow-delay="200ms"
							data-wow-duration="1500ms"
						>
							<div className="about-right">
								<div className="banner-1">
									<img
										src={process.env.PUBLIC_URL + "/img/about-us-banner.webp"}
										alt="about-images"
									/>
								</div>
								<div className="banner-2">
									<img
										src={process.env.PUBLIC_URL + "/img/about-banner-2.webp"}
										alt="about-images"
									/>
									<div className="banner2-inner">
										<div className="play">
											<div
												className="video-popup"
												// onClick={() => setOpen(true)}
											>
												<i className="fas fa-play" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<React.Fragment>
				<ModalVideo
					channel="youtube"
					isOpen={isOpen}
					videoId="IUN664s7N-c"
					autoplay={1}
					onClose={() => setOpen(false)}
				/>
			</React.Fragment>
		</>
	);
}

export default AboutArea;
