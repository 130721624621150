import React from "react";

function Preloader() {
  return (
    <>
      {/* <div className="preloader">
        <div className="loader">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div> */}
      <div className="custom-logo">
        <img src="/img/logo.svg" alt="logo" />
      </div>
    </>
  );
}

export default Preloader;
